var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modal.filtro)?_c('Filtro',{attrs:{"filtro":_vm.modal},on:{"filtro":_vm.carregarFiltro,"filtroFechar":_vm.fecharFiltro}}):_vm._e(),(_vm.detmodal)?_c('Detalhes',{attrs:{"consulta":_vm.objeto},on:{"detclose":_vm.fecharDetalhes}}):_vm._e(),_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"fab":"","small":"","color":"#018656","dark":""},on:{"click":function($event){return _vm.voltarBottom()}}},[_c('v-icon',[_vm._v(" fas fa-arrow-down ")])],1)],1),_c('v-icon',[_vm._v(" fas fa-code ")]),_c('v-toolbar-title',{staticClass:"pl-4"},[_vm._v(" Tratativas pendentes ")]),_c('v-spacer'),(_vm.grid.objetos.itemSelecionado.length > 0)?_c('v-app-bar-nav-icon',{on:{"click":_vm.carregarRastreio}},[_c('v-icon',[_vm._v("fas fa-dolly-flatbed")])],1):_vm._e(),_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.modal.filtro = true}}},[_c('v-icon',[_vm._v("fas fa-search")])],1),_c('v-app-bar-nav-icon',{on:{"click":_vm.fechar}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"alinhamento",attrs:{"cols":"2"}},[_c('v-dialog',{ref:"modal_data_inicio",attrs:{"return-value":_vm.dataInicio,"persistent":"","width":"290px","retain-focus":false},on:{"update:returnValue":function($event){_vm.dataInicio=$event},"update:return-value":function($event){_vm.dataInicio=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"#018656","dense":"","outlined":"","prepend-icon":"fas fa-calendar-alt","label":"Data inicial","readonly":"","value":_vm.formatDateInicioGrid,"clearable":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal.data_inicio),callback:function ($$v) {_vm.$set(_vm.modal, "data_inicio", $$v)},expression:"modal.data_inicio"}},[_c('v-date-picker',{attrs:{"color":"#018656","scrollable":""},model:{value:(_vm.dataInicio),callback:function ($$v) {_vm.dataInicio=$$v},expression:"dataInicio"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#018656"},on:{"click":function($event){_vm.modal.data_inicio = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"#018656"},on:{"click":function($event){return _vm.$refs.modal_data_inicio.save(_vm.dataInicio)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"alinhamento",attrs:{"cols":"2"}},[_c('v-dialog',{ref:"modal_data_final",attrs:{"return-value":_vm.dataFinal,"persistent":"","width":"290px","retain-focus":false},on:{"update:returnValue":function($event){_vm.dataFinal=$event},"update:return-value":function($event){_vm.dataFinal=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"#018656","dense":"","outlined":"","label":"Data final","readonly":"","value":_vm.formatDateFinalGrid,"clearable":"","append-outer-icon":"fas fa-search"},on:{"click:append-outer":function($event){return _vm.carregarObjetos()}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal.data_final),callback:function ($$v) {_vm.$set(_vm.modal, "data_final", $$v)},expression:"modal.data_final"}},[_c('v-date-picker',{attrs:{"color":"#018656","scrollable":""},model:{value:(_vm.dataFinal),callback:function ($$v) {_vm.dataFinal=$$v},expression:"dataFinal"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#018656"},on:{"click":function($event){_vm.modal.data_final = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"#018656"},on:{"click":function($event){return _vm.$refs.modal_data_final.save(_vm.dataFinal)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-6 py-6"},[_c('v-data-table',{staticClass:"elevation-1 pt-4",staticStyle:{"color":"black"},attrs:{"color":"#018656","dense":"","headers":_vm.grid.objetos.cabecalho,"items":_vm.grid.objetos.items,"items-per-page":_vm.grid.objetos.porPagina,"hide-default-footer":"","loading":_vm.grid.objetos.carregando,"item-key":"codigo"},scopedSlots:_vm._u([{key:"item.numero_rastreio",fn:function(ref){
var item = ref.item;
return [_c('button',{staticStyle:{"font-weight":"bold","color":"#018656"},attrs:{"type":"button"},on:{"click":function () { return _vm.carregarDetalhes(item); }}},[_vm._v(" "+_vm._s(item.numero_rastreio)+" ")])]}},_vm._l((_vm.grid.objetos.cabecalho.filter(function (header) { return header.hasOwnProperty('formatter'); }
            )),function(header){return {key:("item." + (header.value)),fn:function(ref){
            var header = ref.header;
            var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true),model:{value:(_vm.grid.objetos.itemSelecionado),callback:function ($$v) {_vm.$set(_vm.grid.objetos, "itemSelecionado", $$v)},expression:"grid.objetos.itemSelecionado"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.grid.objetos.carregando),expression:"grid.objetos.carregando"}],attrs:{"slot":"progress","color":"#018656","indeterminate":""},slot:"progress"})],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"fab":"","small":"","color":"#018656","dark":""},on:{"click":function($event){return _vm.voltarTopo()}}},[_c('v-icon',[_vm._v(" fas fa-arrow-up ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-pagination',{attrs:{"color":"#018656","length":Math.ceil( _vm.grid.objetos.totalRegistros / this.grid.objetos.porPagina ),"total-visible":5},on:{"input":_vm.mudarPagina},model:{value:(_vm.grid.objetos.paginaAtual),callback:function ($$v) {_vm.$set(_vm.grid.objetos, "paginaAtual", $$v)},expression:"grid.objetos.paginaAtual"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"color":"#018656","dense":"","outlined":"","label":"Resultados por página","items":_vm.grid.objetos.qtdsPaginacao,"item-text":"text","item-value":"value"},model:{value:(_vm.grid.objetos.porPagina),callback:function ($$v) {_vm.$set(_vm.grid.objetos, "porPagina", $$v)},expression:"grid.objetos.porPagina"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }